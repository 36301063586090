// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.tournament-leaderboard .leaderboard-item-id {
    position: relative
}

.tournament-leaderboard .leaderboard-item-id .won-medal {
    height: 20px;
    margin-left: auto;
    position: absolute;
    right: 0;
    text-align: right;
    width: 20px
}

.tournament-leaderboard .leaderboard-item-id,.tournament-leaderboard .leaderboard-item-name {
    text-align: left
}

.tournament-leaderboard .leaderboard-item-prize,.tournament-leaderboard .leaderboard-item-score {
    text-align: right
}

.tournament-leaderboard td,.tournament-leaderboard th {
    padding: 12px 10px !important
}

.tournament-leaderboard thead {
    background-color: #bcd7ff
}

.tournament-leaderboard .hightlight-color {
    background-color: #ddebff
}

.tournament-leaderboard .leaderboard-item-name.name-you {
    font-weight: 600
}

.tournament-leaderboard .leaderboard-item-image img {
    height: 25px;
    width: 25px
}`, "",{"version":3,"sources":["webpack://./src/app2.js/css/dashboard.css"],"names":[],"mappings":";AACA;IACI;AACJ;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,QAAQ;IACR,iBAAiB;IACjB;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI,YAAY;IACZ;AACJ","sourcesContent":["\n.tournament-leaderboard .leaderboard-item-id {\n    position: relative\n}\n\n.tournament-leaderboard .leaderboard-item-id .won-medal {\n    height: 20px;\n    margin-left: auto;\n    position: absolute;\n    right: 0;\n    text-align: right;\n    width: 20px\n}\n\n.tournament-leaderboard .leaderboard-item-id,.tournament-leaderboard .leaderboard-item-name {\n    text-align: left\n}\n\n.tournament-leaderboard .leaderboard-item-prize,.tournament-leaderboard .leaderboard-item-score {\n    text-align: right\n}\n\n.tournament-leaderboard td,.tournament-leaderboard th {\n    padding: 12px 10px !important\n}\n\n.tournament-leaderboard thead {\n    background-color: #bcd7ff\n}\n\n.tournament-leaderboard .hightlight-color {\n    background-color: #ddebff\n}\n\n.tournament-leaderboard .leaderboard-item-name.name-you {\n    font-weight: 600\n}\n\n.tournament-leaderboard .leaderboard-item-image img {\n    height: 25px;\n    width: 25px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
