// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel{
    background-color: #eee;
    border: 1px solid #eee;
    border-radius: 5px;
}
.carousel-item img{
border-radius: 5px;
height: 80px;
}`, "",{"version":3,"sources":["webpack://./src/app2.js/css/AdBanner.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,sBAAsB;IACtB,kBAAkB;AACtB;AACA;AACA,kBAAkB;AAClB,YAAY;AACZ","sourcesContent":[".carousel{\n    background-color: #eee;\n    border: 1px solid #eee;\n    border-radius: 5px;\n}\n.carousel-item img{\nborder-radius: 5px;\nheight: 80px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
