import React, { useEffect, useState, useRef } from "react";
import socketIo from "socket.io-client";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import "./Chat.css";
import axios from "axios";

let socket;
const ENDPOINT = "https://socket.a1gaming.co.in/";

const Chat = ({ user }) => {
    const [id, setId] = useState("");
    const [messages, setMessages] = useState([]);
    const [chatInput, setChatInput] = useState("");
    const [isRecording, setIsRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioBlob, setAudioBlob] = useState(null);

    const audioRef = useRef();

    const send = () => {
        if (chatInput.trim()) {
            const room = user?.Phone ? user.Phone.toString() : "defaultRoom";

            socket.emit("message", { message: chatInput, id: user?._id, Phone: user?.Phone, room });
            setChatInput("");
        }
    };

    useEffect(() => {
        socket = socketIo(ENDPOINT, { transports: ["websocket"] });

        socket.on("connect", () => {});

        socket.on("allMessages", (data) => setMessages(data));
        socket.on("sendMessage", (data) => setMessages((prev) => [...prev, data?.message]));
        socket.on("welcome", (data) => setMessages((prev) => [...prev, data]));

        return () => {
            socket.emit("disconnect");
            socket.off();
        };
    }, []);

    useEffect(() => {
        const room = user?.Phone ? user.Phone.toString() : "defaultRoom";
        setId(user?._id);
        socket.emit("joined", { id: user?._id, Phone: user?.Phone, room });
    }, [user]);

    const startRecording = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const recorder = new MediaRecorder(stream);
        recorder.ondataavailable = (event) => setAudioBlob(event.data);
        recorder.start();
        setMediaRecorder(recorder);
        setIsRecording(true);
    };

    const stopRecording = () => {
        mediaRecorder.stop();
        setIsRecording(false);
    };

    const backendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
    const backendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
    const nodeMode = process.env.NODE_ENV;
    const baseUrl = nodeMode === "development" ? backendLocalApiUrl : backendLiveApiUrl;

    const sendAudioMessage = async () => {
        if (audioBlob) {
            const formData = new FormData();
            formData.append("audio", new File([audioBlob], "audioMessage.mp3", { type: "audio/mp3" }));
            formData.append("room", user.Phone.toString());
            formData.append("senderId", user._id);
            formData.append("Phone", user.Phone);
            formData.append("isAdmin", false);

            try {
                const data = await axios.post(`${baseUrl}upload`, formData);
                setAudioBlob(null);
                socket.emit("voiceMessage", data?.data);
            } catch (error) {
                console.error("Error sending audio message:", error);
            }
        }
    };

    return (
        <div className="leftContainer">
            <Container>
                <Row>
                    <Col md={{ span: 12, offset: 0 }}>
                        <Card className="chat-container">
                            <Card.Header className="text-center">
                                <h5>Chat Room</h5>
                            </Card.Header>
                            <Card.Body>
                                <div className="chat-messages">
                                    {messages.map((item, i) => (
                                        <div
                                            key={i}
                                            className={`message ${
                                                item.senderId === id ? "sent" : "received"
                                            }`}
                                        >
                                            <strong>{item.senderId === id ? "You" : item.senderId}</strong>: {" "}
                                            {item.audioUrl ? (
                                                <audio controls src={baseUrl + item.audioUrl} />
                                            ) : (
                                                <p>{item.message}</p>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </Card.Body>
                            <Card.Footer>
                                <Form>
                                    <Form.Group className="d-flex align-items-center" style={{ gap: "10px" }}>
                                        <Form.Control
                                            type="text"
                                            placeholder="Type a message"
                                            value={chatInput}
                                            onChange={(e) => setChatInput(e.target.value)}
                                            onKeyPress={(e) => (e.key === "Enter" ? send() : null)}
                                            className="flex-grow-1 me-2"
                                            style={{ borderColor: "transparent" }}
                                        />
                                        {!isRecording && !audioBlob && (
                                            <Button
                                                variant="outline-gray"
                                                onClick={startRecording}
                                            >
                                                <i className="mdi mdi-microphone"></i>
                                            </Button>
                                        )}
                                        {isRecording && (
                                            <Button
                                                variant="outline-danger"
                                                onClick={stopRecording}
                                                style={{ boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}
                                            >
                                                <i className="mdi mdi-pause"></i>
                                            </Button>
                                        )}
                                        {audioBlob && (
                                            <Button
                                                variant="outline-success"
                                                onClick={sendAudioMessage}
                                                style={{ boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}
                                            >
                                                <i className="mdi mdi-send"></i>
                                            </Button>
                                        )}
                                        <Button
                                            variant="primary"
                                            onClick={send}
                                            style={{ flexBasis: "30%",boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}
                                        >
                                            Send
                                        </Button>
                                    </Form.Group>
                                </Form>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Chat;